<template>
  <section>
    <!-- How it works Section -->
    <v-container class="pa-sm-6 pa-md-12">
      <div class="text-center mb-md-16 mb-8">
        <v-avatar class="mb-6" color="primary" rounded size="x-large">
          <!-- TODO: removed ColorMode -->
          <!--<app-icon-white
            v-if="colorMode.value !== 'dark'"
            size="40"
          ></app-icon-white>-->
          <app-icon-black size="40"></app-icon-black>
        </v-avatar>

        <v-responsive class="text-center mx-auto mb-6 mb-md-12" max-width="700">
          <h2 class="text-h4 font-weight-bold mb-4">
            <div class="text-h5 font-weight-bold mb-2 text-sm-h4">
              {{ $t('SectionsIndexHowItWorks.title') }}
            </div>
          </h2>

          <p class="text-subtitle-1 text-medium-emphasis">
            {{ $t('SectionsIndexHowItWorks.description') }}
          </p>
        </v-responsive>
      </div>

      <v-row>
        <v-col v-for="(item, i) in steps" :key="i" cols="12" md="4">
          <v-responsive class="mx-auto" max-width="450" width="100%">
            <v-list-item class="px-0">
              <v-list-item-title
                class="mb-2 text-subtitle-1 font-weight-medium"
              >
                {{ $t(`steps.${i + 1}.title`) }}
              </v-list-item-title>

              <v-divider
                class="mb-5"
                :color="item.color"
                length="32"
                opacity=".7"
                thickness="3"
              />

              <template #append>
                <span class="text-h2 font-weight-bold opacity-10 mt-n4">
                  0{{ i + 1 }}
                </span>
              </template>
            </v-list-item>

            <p class="text-medium-emphasis">
              {{ $t(`steps.${i + 1}.text`) }}
            </p>
          </v-responsive>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script setup>
// TODO: removed ColorMode
// const colorMode = useColorMode()
const steps = [
  {
    color: 'awesome-3',
  },
  {
    color: 'awesome-2',
  },
  {
    color: 'awesome-1',
  },
]
</script>

