<template>
  <nuxt-picture
    class="awesometechstack-img"
    preload
    alt="AwesomeTechStack hero image"
    src="/img/AwesomeTechStack.png"
    width="100%"
    height="100%"
    quality="80"
    fit="contain"
    style="width: 100%; height: 100%; object-fit: contain"
  >
  </nuxt-picture>
</template>

<style lang="scss">
.awesometechstack-img {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
