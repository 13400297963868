<template>
  <v-app>
    <AppBar />
    <AppNavigationDrawer />
    <v-main class="text-center">
      <div class="py-16">
        <p class="mt-16 font-weight-medium text-primary">404</p>

        <p class="font-weight-bold text-sm-h3 text-h4 mt-2'">Page not found</p>

        <p class="mt-4 mb-6 text-body-1 text-medium-emphasis">
          The awesome page you're looking for isn't here.
        </p>
      </div>
    </v-main>
    <app-footer></app-footer>
  </v-app>
</template>

<script setup>
import { joinURL } from 'ufo'
import { onAuthStateChanged, onIdTokenChanged } from 'firebase/auth'
import { useStore } from '@/composables/store'

import { useTheme } from 'vuetify'
const theme = useTheme()

const { grantConsent, revokeConsent } = useGtag()
const {
  cookiesEnabled,
  cookiesEnabledIds,
  isConsentGiven,
  isModalActive,
  moduleOptions,
} = useCookieControl()

const store = useStore()
const { $auth } = useNuxtApp()

defineProps({
  error: Object,
})
const handleError = () => clearError({ redirect: '/' })

/* const colorMode = useColorMode()

const cookieColorMode = useCookie('color-mode', {
  maxAge: 31536000,
  watch: true,
})
const cookieColorModePreference = useCookie('color-mode-preference', {
  maxAge: 31536000,
  watch: true,
})

if (cookieColorMode) {
  colorMode.value = cookieColorMode.value
}

cookieColorMode.value = colorMode.value
cookieColorModePreference.value = colorMode.preference

watch(
  () => colorMode.preference,
  (val) => {
    cookieColorModePreference.value = val
  }
)

watch(
  () => colorMode.value,
  (val) => {
    cookieColorMode.value = val
    // this is needed, if you reload with clear cache the cards would be white, dunno why
    setTimeout(() => {
      theme.global.name.value = val
    })
  }
)

// I cannot explain this, but in local it stops flickering
if (process.server) {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  } else {
    theme.global.name.value = 'light'
  }
}

onMounted(() => {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  }
})

onMounted(() => {
  if (colorMode.value) {
    theme.global.name.value = colorMode.value
  }
  onAuthStateChanged($auth, (user) => {
    store.$patch({
      user: user,
    })
    store.setUserLoading(false)
    if (user) {
      store.hideSignInSignUpDialog()
    }
    store.onAuthStateChangedAction()
  })

  onIdTokenChanged($auth, (user) => {
    store.$patch({
      user: user,
    })
    store.onIdTokenChangedAction()
  })
})*/
</script>
