<template>
  <app-container>
    <!-- Hero Section -->
    <v-container class="position-relative">
      <v-responsive content-class="d-flex align-center justify-center">
        <div
          class="w-100 text-center justify-center d-flex flex-column align-center"
          style="max-width: 770px"
        >
          <h1 class="text-h4 text-md-h2 font-weight-bold my-6">
            Website Tech Stack Analysis
          </h1>

          <div class="text-body-1 text-medium-emphasis mb-10">
            Uncover the secrets of top-performing websites with
            AwesomeTechStack's in-depth technology analysis. Gain insights into
            the metrics and tech stacks of various websites.
          </div>

          <analyze-bar
            variant="solo-filled"
            style="max-width: 570px; width: 100%"
          ></analyze-bar>

          <!--<div class="d-flex ga-4 justify-center flex-sm-row flex-column">
              <v-btn
                size="large"
                :to="{ name: 'products-website-analyzer' }"
                class="d-flex flex-grow-1 flex-md-shrink-1 text-none"
                color="primary"
              >
                <app-icon-white
                  v-if="colorMode.value !== 'dark'"
                  class="mr-4"
                  size="32"
                ></app-icon-white>
                <app-icon-black
                  v-if="colorMode.value === 'dark'"
                  class="mr-4"
                  size="32"
                ></app-icon-black>
                Website analyzer
              </v-btn>

              <v-btn
                class="d-flex flex-grow-1 flex-md-shrink-1 text-none"
                flat
                size="large"
                :append-icon="mdiChevronRight"
                :to="{ name: 'why' }"
                color=""
                rounded="lg"
                border
              >
                Learn more
              </v-btn>
            </div>-->
        </div>
        <div class="v-bg position-absolute top-0 right-0 left-0 bottom-0">
          <div
            aria-hidden="true"
            class="overflow-hidden opacity-20 w-100 h-100"
          />
        </div>
      </v-responsive>
    </v-container>

    <!-- Feature 1 Section -->
    <v-container class="pa-sm-6 pa-md-12">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <div
            class="d-inline-flex align-center font-weight-medium text-primary"
          >
            <app-icon-pulse :size="32" class="mr-3"></app-icon-pulse>
            {{ $t('SectionsIndexWebsiteAnalyzer.title') }}
          </div>

          <p class="mt-2 text-h5 font-weight-bold text-sm-h4">
            {{ $t('SectionsIndexWebsiteAnalyzer.title2') }}
          </p>

          <p class="my-4 text-body-1 text-medium-emphasis">
            {{ $t('SectionsIndexWebsiteAnalyzer.description') }}
          </p>

          <v-btn
            class="text-capitalize mr-3"
            color="primary"
            variant="flat"
            :to="{ name: 'products-website-analyzer' }"
          >
            Get Started
          </v-btn>

          <div class="mt-8">
            <v-list-item v-for="i in 3" :key="i" class="px-0 py-2">
              <template #prepend>
                <v-icon color="primary" :icon="mdiCheck" size="small" />
              </template>
              <template #subtitle>
                <p class="text-body-2 font-weight-bold">
                  {{ $t(`SectionsIndexWebsiteAnalyzer.highlights[${i}]`) }}
                </p>
              </template>
            </v-list-item>
            <v-list-item class="px-0 py-2">
              <template #prepend>
                <v-icon color="primary" :icon="mdiCheck" size="small" />
              </template>
              <template #subtitle>
                <p class="text-body-2 font-weight-bold">
                  {{ technologies }}
                  technologies in
                  {{ categories }}
                  categories
                </p>
              </template>
            </v-list-item>
          </div>
        </v-col>

        <v-col class="d-flex align-center" cols="12" md="6">
          <awesometechstack-img style="opacity: 0.97" />
        </v-col>
      </v-row>
    </v-container>

    <!-- FAQ Section-->
    <v-container class="pa-6 pa-md-12">
      <v-responsive class="mx-auto text-center" max-width="700">
        <p class="text-h5 font-weight-bold text-sm-h4">
          Frequently Asked Questions
        </p>

        <p class="mt-4 mb-12 text-body-1 text-medium-emphasis">
          Find answers to common questions about our website technology stack
          analyzer and discover how it can help you enhance your site's tech
          stack efficiency and performance.
        </p>
      </v-responsive>

      <v-row justify="center">
        <v-col v-for="(item, i) in faqItems" :key="i" cols="12" lg="6">
          <v-list-item class="mb-4 px-0">
            <template #title> {{ i + 1 }}. {{ item.title }} </template>

            <template #subtitle>
              <p class="mt-2 text-body-1">{{ item.subtitle }}</p>
            </template>
          </v-list-item>
        </v-col>
      </v-row>
    </v-container>

    <!-- Pricing Section -->
    <v-container class="pa-md-12 pa-sm-6">
      <v-responsive class="text-center mx-auto mb-6 mb-md-12" max-width="700">
        <p class="font-weight-medium text-primary">Pricing</p>

        <h1 class="text-h4 font-weight-bold mb-4">Pricing & Plans</h1>

        <p class="text-subtitle-1 text-medium-emphasis">
          Access 10 free website analyses per month or upgrade to a paid plan
          for in-depth analysis and multiple website tracking. Flexible plans
          and easy cancellation with AwesomeTechStack.
        </p>
      </v-responsive>

      <v-row align="center" class="mb-6" justify="center" :no-gutters="lgAndUp">
        <v-col v-for="(item, i) in plans" :key="i" cols="12" lg="4">
          <v-card
            :border="mdAndDown ? true : i == 0 ? 's t b' : true"
            class="pa-8 d-flex flex-column mx-auto"
            :color="i == 0 ? 'default' : 'primary'"
            :elevation="i == 0 ? 0 : 20"
            max-width="400"
            :min-height="i == 0 ? 420 : 500"
            :rounded="mdAndDown ? 'xl' : i == 0 ? 's-xl' : 'xl'"
            variant="elevated"
          >
            <p class="mb-4 font-weight-bold">{{ item.title }}</p>

            <div class="text-h4 mb-4 font-weight-bold">
              ${{ item.cost }}

              <small class="text-body-2"> /{{ item.per || 'month' }} </small>
            </div>

            <p class="text-subtitle-2 pb-3">
              {{ item.subtitle }}
            </p>

            <v-list-item
              v-for="(feature, _index) in item.features"
              :key="_index"
              class="px-0 text-body-2"
            >
              <v-icon
                class="mr-1"
                :color="i == 0 ? 'primary' : 'default'"
                :icon="mdiCheck"
              />

              {{ feature }}
            </v-list-item>

            <v-btn
              v-show="
                route.name !== 'user-subscription' &&
                store.user &&
                store.user.plan === 'free'
              "
              :to="{ name: 'user-subscription' }"
              variant="flat"
              width="100%"
              class="mt-3 mx-auto text-none"
              :color="i == 0 ? 'primary' : 'default'"
              max-height="36"
            >
              Get started today
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-col cols="12">
        <div class="d-flex mx-auto" style="max-width: 800px">
          <pricing-calculator class="w-100"></pricing-calculator>
        </div>
      </v-col>
    </v-container>

    <!-- How it works Section -->
    <v-container class="pa-sm-6 pa-md-12">
      <sections-index-how-it-works></sections-index-how-it-works>
    </v-container>

    <!-- Contact Us Section -->
    <v-container class="pa-md-12 pa-sm-6 text-center">
      <h1 class="font-weight-bold mb-4 text-h5 text-sm-h4">
        {{ $t('AppContactUs.title') }}
      </h1>

      <p class="text-subtitle-2 opacity-60 mb-6 w-100 w-md-50 mx-auto">
        {{ $t('AppContactUs.subtitle') }}
      </p>

      <v-btn
        :append-icon="mdiArrowRight"
        class="text-none"
        color="primary"
        size="small"
        variant="flat"
        :text="$t('AppContactUs.buttonText')"
        :to="{ name: 'contact' }"
      />
    </v-container>
  </app-container>
</template>

<script setup>
const route = useRoute()
const store = useStore()
import { mdiArrowRight, mdiCheck } from '@mdi/js'
import { useDisplay } from 'vuetify'
import {
  technologies,
  categories,
} from 'awesometechstack-wappalyzer/awesomeextension/webextension/stats.json'

store.setBreadcrumbs(null)

const { lgAndUp, mdAndDown } = useDisplay()

const { data: page } = await useAsyncData('pageData', () =>
  queryContent(route.path).only(['title', 'description']).findOne()
)

const title = page?.value.head?.title || page.value.title
const description = page?.value.head?.description || page.value.description

useSeoMeta({
  title: title,
  ogTitle: title,
  description: description,
  ogDescription: description,
})

import { useTheme } from 'vuetify'

const theme = useTheme()

const faqItems = [
  {
    title: 'Can I use the technology analyzer for commercial purposes?',
    subtitle:
      'Yes, you can use the technology analyzer for commercial purposes. However, we ask that you adhere to our terms of use and avoid utilizing the results for any illegal or unethical activities. This ensures a fair and compliant use of our services.',
  },
  {
    title: 'Can AwesomeTechStack scan and identify online shop setups?',
    subtitle:
      'Yes, AwesomeTechStack can scan for ecommerce websites and identify their online shop setups. Our technology analyzer detects and provides insights on over 370 ecommerce technologies, including popular platforms such as Magento, Shopify, WooCommerce, and BigCommerce. This information helps B2B customers understand the ecommerce landscape and optimize their marketing strategies.',
  },
  {
    title: 'What is the awesomeness score?',
    subtitle:
      'The awesomeness score is a combined and weighted score of the technology, version, and quality scores, ranging from 0-100. It represents the overall awesomeness of a website or technology. This holistic score helps users gauge the effectiveness and modernity of their tech stack.',
  },
  {
    title: 'Why is it important to keep my tech stack up-to-date?',
    subtitle:
      'Keeping your tech stack up-to-date ensures you are using the latest and most stable versions of the technologies. This improves website performance and security, providing a better user experience. Outdated technologies may have known vulnerabilities or performance issues that can be addressed by updating.',
  },
  {
    title: "How do I improve my website's scores?",
    subtitle:
      "To improve your website's scores: keep your tech stack up-to-date with the latest versions, optimize website performance by minifying resources, reducing server response times, and optimizing images, improve accessibility, enhance SEO, and make your website a progressive web app (PWA) by adding offline functionality and push notifications.",
  },
  {
    title: 'How accurate is the technology analyzer?',
    subtitle:
      'The technology analyzer uses a combination of automated scanning and manual review to ensure the most accurate results possible. However, it is possible that some technologies may not be detected or may be misidentified. We continually work to improve detection accuracy.',
  },
]

const plans = [
  {
    title: 'Free',
    subtitle: 'A simple start for everyone',
    cost: 0,
    features: ['10 free analysis per month', 'Insights'],
  },
  {
    title: 'Pay-as-you-go',
    subtitle: 'Pay only for what you use',
    cost: 0.035,
    per: 'analysis',
    features: [
      'API access',
      '10 free analysis per month',
      'Unlimited analysis',
      'Insights',
      'E-Mail support',
      'Access to upcoming features',
    ],
  },
]
</script>
