<template>
  <v-footer class="pt-6 pt-md-16 pb-0 d-flex flex-column" border="t">
    <v-row>
      <v-col cols="12" lg="4" md="12">
        <div class="align-center d-flex mt-1 mb-6">
          <app-icon class="ml-2 mr-2" size="29"></app-icon>
          AwesomeTechStack
        </div>

        <p>
          Empowering digital excellence through advanced tech stack insights.
        </p>

        <div class="mt-3">
          <v-btn
            v-for="item in socialLinks"
            :key="item.href"
            :icon="item.icon"
            size="small"
            variant="text"
          />
        </div>
      </v-col>

      <v-col
        v-for="(item, i) in store.footerNavigation"
        :key="i"
        cols="6"
        lg="2"
        md="3"
      >
        <v-list nav bg-color="transparent" subheader class="pa-0 transparent">
          <v-list-subheader tag="h4">{{ item.text }}</v-list-subheader>
          <v-list-item
            v-for="link in item.links"
            :key="link.text"
            density="compact"
            :to="link.to"
            :title="link.text"
          >
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-divider class="w-100 mt-12"></v-divider>
    <p class="py-4 text-caption text-right">
      &copy; 2019-{{ new Date().getFullYear() }} - AwesomeTechStack. All rights
      reserved.
    </p>
  </v-footer>
</template>

<script setup>
const route = useRoute()
import { mdiFacebook, mdiTwitter, mdiLinkedin, mdiInstagram } from '@mdi/js'

const icons = [mdiFacebook, mdiTwitter, mdiLinkedin, mdiInstagram]

import { useStore } from '@/composables/store'
const store = useStore()

const socialLinks = [
  {
    href: 'https://twitter.com/awesotechstack',
    icon: mdiTwitter,
  },
  {
    href: 'https://www.linkedin.com/company/awesometechstack/',
    icon: mdiLinkedin,
  },
  {
    href: 'https://www.instagram.com/awesometechstack/',
    icon: mdiInstagram,
  },
  {
    href: 'https://www.facebook.com/people/AwesometechStack/100088674737136/',
    icon: mdiFacebook,
  },
]
</script>

