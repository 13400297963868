<template>
  <v-breadcrumbs class="flex-grow-1 mx-n4" :items="displayedItems">
    <template #divider>
      <v-icon class="mx-n2" color="disabled" :icon="mdiChevronRight" />
    </template>
  </v-breadcrumbs>
</template>

<script setup>
import { mdiChevronRight } from '@mdi/js'
import { useDisplay } from 'vuetify'

const { mobile } = useDisplay()

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
})

const displayedItems = computed(() => {
  // If on mobile, show only the last two items
  if (mobile.value) {
    return props.items.slice(-2)
  }

  return props.items
})
</script>