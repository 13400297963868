<template>
  <v-card class="mx-auto" width="100%" max-width="400">
    <template v-slot:title>
      <div class="d-flex justify-space-between align-center">
        <div>{{ $t(title) }}</div>
        <v-btn
          v-if="!standalone"
          :disabled="store.userLoading"
          @click="$emit('close')"
          :icon="mdiClose"
          variant="text"
        ></v-btn>
      </div>
    </template>
    <div v-if="showSignUp || store.authError.message" class="pa-4">
      <div v-if="showSignUp">
        <v-alert text dense type="info">
          {{ $t('SignInSignUpCard.SignUpPromo') }}
        </v-alert>
      </div>
      <v-alert v-if="store.authError.message" text dense type="error">
        <template v-if="store.authError.code === 'auth/user-disabled'">
          {{ $t('SignInSignUpCard.userDisabled') }}
        </template>
        <template
          v-else-if="store.authError.code === 'auth/email-already-exists'"
        >
          {{ $t('SignInSignUpCard.emailAlreadyExists') }}
        </template>
        <template v-else-if="store.authError.code === 'auth/wrong-password'">
          {{ $t('SignInSignUpCard.invalidPassword') }}
        </template>
        <template
          v-else-if="store.authError.code === 'auth/email-already-in-use'"
        >
          {{ $t('SignInSignUpCard.emailInUse') }}
          <p>{{ $t('SignInSignUpCard.signingIn') }}</p>
        </template>
        <template
          v-else-if="store.authError.code === 'auth/cancelled-popup-request'"
        >
          {{ $t('SignInSignUpCard.operationCancelled') }}
        </template>
        <template
          v-else-if="store.authError.code === 'auth/popup-closed-by-user'"
        >
          {{ $t('SignInSignUpCard.popupClosed') }}
        </template>
        <template v-else-if="store.authError.code === 'auth/weak-password'">
          {{ $t('SignInSignUpCard.weakPassword') }}
        </template>
        <template v-else-if="store.authError.code === 'auth/invalid-email'">
          {{ $t('SignInSignUpCard.invalidEmail') }}
        </template>
        <template v-else-if="store.authError.code === 'auth/user-not-found'">
          {{ $t('SignInSignUpCard.userNotFound') }}
        </template>
        <template
          v-else-if="
            store.authError.code ===
            'auth/account-exists-with-different-credential'
          "
        >
          {{ $t('SignInSignUpCard.accountExists') }}
        </template>
        <template v-else-if="store.authError.code === 'auth/internal-error'">
          {{ $t('SignInSignUpCard.internalError') }}
        </template>
        <template v-else-if="store.authError.code === 'auth/popup-blocked'">
          {{ $t('SignInSignUpCard.popupBlocked') }}
        </template>
        <template v-else>
          {{ $t('SignInSignUpCard.defaultErrorMessage') }}
        </template>
      </v-alert>
    </div>
    <template v-if="!showResetPassword">
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              block
              class="text-black"
              color="white"
              size="large"
              @click="googleSign"
              variant="elevated"
            >
              <svg
                aria-hidden="true"
                class="native svg-icon iconGoogle mr-2"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z"
                  fill="#4285F4"
                ></path>
                <path
                  d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z"
                  fill="#34A853"
                ></path>
                <path
                  d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z"
                  fill="#EA4335"
                ></path>
              </svg>
              {{ $t('SignInSignUpCard.continueWithGoogle') }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              size="large"
              class="text-black"
              color="white"
              @click="gitHubSign"
              variant="elevated"
            >
              <v-icon color="black" start>{{ mdiGithub }}</v-icon>
              {{ $t('SignInSignUpCard.continueWithGitHub') }}
            </v-btn>
          </v-col>
        </v-row>
        <div class="mt-7 d-flex align-center">
          <v-divider></v-divider>
          <div class="px-4 caption text-center" style="white-space: nowrap">
            {{ $t('SignInSignUpCard.continueWithEmail') }}
          </div>
          <v-divider></v-divider>
        </div>
      </v-container>
    </template>
    <template v-if="showSignUp">
      <v-card-text class="pb-0">
        <v-form
          ref="signupForm"
          v-model="validSignUp"
          @submit.prevent.stop="signUp"
          autocomplete="on"
        >
          <v-text-field
            v-model="email"
            name="email"
            :label="$t('SignInSignUpCard.SignInSignUpForm.emailAddress')"
            :rules="emailRules"
            variant="outlined"
          ></v-text-field>
          <v-text-field
            v-model="password"
            autocomplete="new-password"
            name="password"
            :type="showPassword ? 'text' : 'password'"
            :append-inner-icon="showPassword ? mdiEye : mdiEyeOff"
            :label="$t('SignInSignUpCard.SignInSignUpForm.password')"
            @click:append-inner="showPassword = !showPassword"
            variant="outlined"
          ></v-text-field>
          <v-btn
            block
            :loading="store.userLoading || autoSignInRunning"
            :disabled="!validSignUp"
            size="large"
            type="submit"
          >
            {{ $t('SignInSignUpCard.SignInSignUpForm.signUp') }}
          </v-btn>
        </v-form>
        <div class="d-flex justify-center">
          <v-btn
            class="pl-0 pt-4"
            :ripple="false"
            variant="plain"
            @click="alreadyHaveAnAccount"
          >
            {{ $t('SignInSignUpCard.SignInSignUpForm.alreadyHaveAnAccount') }}
          </v-btn>
        </div>
        <p class="py-4 mb-0 text-body-2">
          {{ $t('SignInSignUpCard.SignInSignUpForm.wantToAccess') }}
          <nuxt-link class="text-primary" :to="{ name: 'terms' }">{{
            $t('SignInSignUpCard.SignInSignUpForm.termsOfService')
          }}</nuxt-link>
          {{ $t('SignInSignUpCard.SignInSignUpForm.and') }}
          <nuxt-link class="text-primary" :to="{ name: 'privacy' }">{{
            $t('SignInSignUpCard.SignInSignUpForm.privacyPolicy')
          }}</nuxt-link>
        </p>
      </v-card-text>
    </template>
    <template v-else-if="showResetPassword">
      <v-card-text>
        <v-form
          v-model="valid"
          @submit.prevent.stop="resetPassword"
          autocomplete="on"
        >
          <v-text-field
            variant="outlined"
            v-model="email"
            name="email"
            :label="$t('SignInSignUpCard.emailLabel')"
          ></v-text-field>
          <v-btn block :loading="store.userLoading" size="large" type="submit">
            {{ $t('SignInSignUpCard.verifyButton') }}
          </v-btn>
        </v-form>
      </v-card-text>
      <div class="d-flex justify-center pb-2">
        <v-btn
          :ripple="false"
          variant="plain"
          @click="showResetPassword = false"
        >
          {{ $t('SignInSignUpCard.signInButton') }}
        </v-btn>
      </div>
    </template>
    <template v-else>
      <v-card-text v-if="resetPasswordSuccess || addedUser">
        <v-alert v-if="resetPasswordSuccess" text class="px-4" type="success">
          {{
            $t('SignInSignUpCard.resetPasswordSuccessMessage', { email: email })
          }}
        </v-alert>
        <v-alert v-if="addedUser" class="px-4" outlined type="success">
          {{ $t('SignInSignUpCard.addedUserMessage', { email: email }) }}
        </v-alert>
      </v-card-text>
      <v-card-text>
        <v-form
          ref="signinForm"
          v-model="validSignIn"
          @submit.prevent.stop="signIn"
          autocomplete="on"
        >
          <v-text-field
            variant="outlined"
            v-model="email"
            name="email"
            :label="$t('SignInSignUpCard.emailLabel')"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            variant="outlined"
            v-model="password"
            autocomplete="current-password"
            name="password"
            :type="showPassword ? 'text' : 'password'"
            :append-inner-icon="showPassword ? mdiEye : mdiEyeOff"
            :label="$t('SignInSignUpCard.passwordLabel')"
            @click:append-inner="showPassword = !showPassword"
          ></v-text-field>
          <v-btn
            block
            :loading="store.userLoading"
            size="large"
            type="submit"
            color="primary"
          >
            {{ $t('SignInSignUpCard.signInButton') }}
          </v-btn>
        </v-form>
      </v-card-text>
      <div class="py-4 d-flex flex-column justify-center">
        <v-btn :ripple="false" variant="plain" @click="showSignUp = true">
          {{ $t('SignInSignUpCard.createAccountButton') }}
        </v-btn>
        <v-btn
          :ripple="false"
          variant="plain"
          @click="showResetPassword = true"
        >
          {{ $t('SignInSignUpCard.resetPasswordButton') }}
        </v-btn>
      </div>
    </template>
  </v-card>
</template>

<script setup>
import {
  mdiEye,
  mdiEyeOff,
  mdiFacebook,
  mdiGithub,
  mdiArrowRight,
  mdiClose,
} from '@mdi/js'
import { useStore } from '@/composables/store'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  GithubAuthProvider,
} from 'firebase/auth'

const { gtag } = useGtag()
const store = useStore()

const showPassword = ref(false)

const props = defineProps({
  init: { type: String, default: 'signin' },
  standalone: { type: Boolean, default: true },
})

const { $auth } = useNuxtApp()

const signinForm = ref()
const signupForm = ref()

const passwordRules = [
  (v) => !!v || 'Password is required',
  (v) => (v && v.length >= 6) || 'Password must be at least 6 characters',
]
const emailRules = [
  (v) => !!v || 'E-mail address is required',
  (v) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      v
    ) || 'E-mail address must be valid',
]
const validSignIn = ref(true)
const validSignUp = ref(true)
const showSignUp = ref(props.init)
const showResetPassword = ref(false)
const resetPasswordSuccess = ref(false)
const addedUser = ref(false)
const email = ref('')
const password = ref('')
const error = ref({
  message: '',
  code: undefined,
})
const signInMethod = ref('signInWithPopup')
const signInMethods = { signInWithRedirect, signInWithPopup }
const autoSignInRunning = ref(false)

const title = computed(() => {
  if (showSignUp.value) {
    return 'SignInSignUpCard.signUpTitle'
  } else if (showResetPassword.value) {
    return 'SignInSignUpCard.resetPasswordTitle'
  } else {
    return 'SignInSignUpCard.signInTitle'
  }
})

watch(showSignUp, (val) => {
  if (val) {
    store.resetAuthError()
    setTimeout(() => {
      signupForm.value.validate()
    })
  }
})

onMounted(() => {
  if (store.showSignInSignUpDialog === 'signin' && showSignUp.value) {
    showSignUp.value = false
    showResetPassword.value = false
  } else if (store.showSignInSignUpDialog === 'signup' && !showSignUp.value) {
    showSignUp.value = true
    showResetPassword.value = false
  }
})

const googleSign = async () => {
  store.setUserLoading(true)
  setTimeout(async () => {
    const provider = new GoogleAuthProvider()
    $auth.useDeviceLanguage()
    try {
      await signInMethods[signInMethod.value]($auth, provider)
      gtag('event', 'signIn', { name: 'Google' })
      gtag('event', 'login', { method: 'Google' })
    } catch (err) {
      const { code } = err
      store.setAuthError(err)
      if (code === 'auth/popup-blocked') {
        tryOtherSignInMethod({ fn: googleSign })
      }
    } finally {
      store.setUserLoading(false)
    }
  })
}

const microsoftSignIn = async () => {
  store.setUserLoading(true)
  setTimeout(async () => {
    const provider = new OAuthProvider('microsoft.com')
    provider.setCustomParameters({
      tenant: 'fedfcc02-bdeb-405f-8366-741370f41ecb',
    })
    $auth.useDeviceLanguage()
    try {
      await signInMethods[signInMethod.value]($auth, provider)
      gtag('event', 'signIn', { name: 'Microsoft' })
      gtag('event', 'login', { method: 'Microsoft' })
    } catch (err) {
      const { code } = err
      store.setAuthError(err)
      if (code === 'auth/popup-blocked') {
        tryOtherSignInMethod({ fn: microsoftSignIn })
      }
    } finally {
      store.setUserLoading(false)
    }
  })
}

const gitHubSign = async () => {
  store.setUserLoading(true)
  setTimeout(async () => {
    const provider = new GithubAuthProvider()
    $auth.useDeviceLanguage()
    try {
      await signInMethods[signInMethod.value]($auth, provider)
      gtag('event', 'signIn', { name: 'GitHub' })
      gtag('event', 'login', { method: 'GitHub' })
    } catch (err) {
      const { code } = err
      store.setAuthError(err)
      if (code === 'auth/popup-blocked') {
        tryOtherSignInMethod({ fn: gitHubSign })
      }
    } finally {
      store.setUserLoading(false)
    }
  })
}

const signIn = async () => {
  await signinForm.value.validate()
  if (validSignIn.value) {
    store.setUserLoading(true)
    setTimeout(async () => {
      try {
        await signInWithEmailAndPassword($auth, email.value, password.value)
        addedUser.value = false
        resetPasswordSuccess.value = false
      } catch (err) {
        console.log(err)
        store.setAuthError(err)
      } finally {
        store.setUserLoading(false)
        autoSignInRunning.value = false
      }
    })
  }
}

const alreadyHaveAnAccount = () => {
  gtag('event', 'signUp', { name: 'already have an account' })
  showSignUp.value = false
}

const signUp = async () => {
  if (signupForm.value) {
    await signupForm.value.validate()
    if (validSignUp.value) {
      store.setUserLoading(true)
      setTimeout(async () => {
        try {
          await createUserWithEmailAndPassword(
            $auth,
            email.value,
            password.value
          )
          await signInWithEmailAndPassword($auth, email.value, password.value)
          const newUser = $auth.currentUser
          await newUser.sendEmailVerification()
          addedUser.value = true
          showSignUp.value = false
          // Add your GTM events here
        } catch (err) {
          const { code } = err
          if (code === 'auth/email-already-in-use') {
            store.setUserLoading(false)
            await new Promise((resolve) => {
              store.setAuthError(err)
              autoSignInRunning.value = true
              setTimeout(() => {
                showSignUp.value = false
                setTimeout(async () => {
                  await signIn()
                  resolve()
                }, 300)
              }, 300)
            })
          } else {
            store.setAuthError(err)
          }
        } finally {
          autoSignInRunning.value = false
          store.setUserLoading(false)
        }
      })
    }
  }
}

const resetPassword = async () => {
  store.setUserLoading(true)
  setTimeout(async () => {
    try {
      await sendPasswordResetEmail($auth, email.value)
      gtag('event', 'user', { name: 'reset password' })
      resetPasswordSuccess.value = true
      showResetPassword.value = false
    } catch (err) {
      store.setAuthError(err)
    } finally {
      store.setUserLoading(false)
    }
  })
}

const tryOtherSignInMethod = (fn) => {
  if (signInMethod.value !== 'signInWithRedirect') {
    signInMethod.value = 'signInWithRedirect'
    fn()
  }
}
</script>
