<template>
  <v-app-bar flat class="app-bar" border="b" color="background">
    <template v-slot:title>
      <app-logo class="text-none" />
    </template>

    <v-spacer />
    <div class="d-none d-lg-block align-center text-truncate">
      <template v-for="item in store.appBarNavigation">
        <v-btn
          class="text-none"
          v-if="!item.links || item.to"
          :key="item.text"
          :variant="item.outlined ? 'outlined' : 'text'"
          :to="item.to ? item.to : undefined"
          @click="onClick(item)"
        >
          {{ item.text }}
        </v-btn>

        <v-menu v-else :key="`menu-${item.text}`" bottom offset-y centered>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" class="text-none">
              {{ item.text }}
              <v-icon size="small" end>{{ mdiChevronDown }}</v-icon>
            </v-btn>
          </template>

          <v-list nav role="menu">
            <v-list-subheader v-if="item.showHeader">{{
              item.text
            }}</v-list-subheader>
            <v-hover v-for="(link, index) in item.links">
              <template #default="{ props: hoverProps, isHovering }">
                <v-list-subheader
                  :key="`appbar-subheader-${index}`"
                  v-if="link.isSubheader"
                  >{{ link.text }}</v-list-subheader
                >
                <v-list-item
                  v-else
                  v-bind="hoverProps"
                  rounded="lg"
                  :key="`appbar-link-${index}`"
                  role="menuitem"
                  :title="link.text"
                  :subtitle="link.description"
                  :to="link.to"
                >
                  <template v-slot:prepend>
                    <app-icon
                      class="mr-4"
                      v-if="link.appIcon"
                      :size="46"
                    ></app-icon>
                    <v-avatar
                      v-else-if="link.icon"
                      :color="isHovering ? 'white' : 'surface-light'"
                      :icon="link.icon"
                      rounded
                      class="my-1"
                    >
                      <v-icon
                        :color="isHovering ? 'primary' : 'medium-emphasis'"
                      ></v-icon>
                    </v-avatar>
                  </template>
                  <template v-slot:title>
                    <div v-html="link.text"></div>
                  </template>
                  <template v-slot:subtitle>
                    <div v-html="link.description"></div>
                  </template>
                </v-list-item>
              </template>
            </v-hover>
          </v-list>
        </v-menu>
      </template>
      <!-- authentication -->
      <sign-in-btn class="text-none" text></sign-in-btn>
      <sign-up-btn class="text-none" outlined text></sign-up-btn>

      <user-menu v-if="store.isAuthenticated || store.userLoading"></user-menu>
    </div>
    <template v-slot:append>
      <!-- TODO: removed colormode -->
      <!--<color-mode-btn class="d-none d-lg-block"></color-mode-btn>-->
      <v-app-bar-nav-icon
        class="d-block d-lg-none"
        aria-label="Menu"
        @click.stop="toggleDrawer"
      />
    </template>
    <template
      #extension
      v-if="
        store.breadcrumbs && store.breadcrumbs.length > 0 && display.mdAndUp
      "
    >
      <v-breadcrumbs
        bg-color="surface"
        class="flex-grow-1 mx-n2 h-100 border-t"
        density="compact"
      >
        <div class="flex-1-1 mx-auto text-subtitle-2">
          <v-container class="py-0" fluid>
            <AppBreadcrumbs :items="store.breadcrumbs" />
          </v-container>
        </div>
      </v-breadcrumbs>
    </template>
  </v-app-bar>
</template>

<script setup>
import { useStore } from '@/composables/store'
import {
  mdiChevronDown,
  mdiAccount,
  mdiPost,
  mdiChevronRight,
  mdiNewBox,
} from '@mdi/js'
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
const store = useStore()
const drawer = computed({
  get() {
    return store.drawer
  },
  set(val) {
    store.setDrawer(val)
  },
})

const onClick = (item) => {
  if (item.action) {
    store[item.action]()
  }
}

const toggleDrawer = () => {
  drawer.value = !drawer.value
}
</script>

<style lang="scss">
.app-bar {
  .v-toolbar-title {
    min-width: 250px;
  }
}
</style>